import React from 'react'
import ConfirmGuest from '../Dashboard/ConfirmGuest'
import ConfirmGeneral from './ConfirmGeneral'


export default function ConfirmGuestClient({data , setOpen , color , guest ,event , font , textColor, playyerTrue , extraInf}) {

  let maxNum = extraInf?.maxNum ? extraInf.maxNum : 5

  return (
    <div>
    {
      guest.id ? 

      <ConfirmGuest textColor={textColor} font={font} data={data} setOpenModal={setOpen} color={color}/>
      : 

      <ConfirmGeneral color={color} data={data} setOpenModal={setOpen} event={event} maxNum={maxNum}/>

    }
    </div>
  )
}
