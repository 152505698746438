import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

require('dayjs/locale/es')


export default function MicroEventsElegance({events , placesPhotos , extra , bgColor}) {

  dayjs.locale('es')

  const [eventsLocal , setEvents] = useState(events)
  const eventName = ["Boda Religiosa","Despedida de soltera","Recepción" , "Boda Civil", "Misa"]
  // var localizedFormat = require('dayjs/plugin/localizedFormat')
  // dayjs.extend(localizedFormat)

  const timeLocale = extra.timeLocaleLenght ?? "LLL"
  
  useEffect(()=>{

    const newArr = eventsLocal.map(event=>{

      if(eventName.includes(event.title)){
        let img = placesPhotos.filter(e=> e.name == event.title)
        return {...event, image: img.length ? img[0].image : undefined}
      }else{
        return event
      }
    })

    setEvents(newArr)

    

  },[placesPhotos])


  return (
    <div className={` mt-4 bg-[${bgColor ?? "[#EDEBEA]"}}]`} >
    <div className="z-10 font-serif relative px-4 pt-6 pb-20 sm:px-6 lg:px- lg:pt-6 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3  sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-2xl uppercase font-bold tracking-tight text-gray-900 sm:text-2xl mt-10">{extra.titleMicroEvents ? extra.titleMicroEvents : "Celebra con nosotros" }</h2>
          <p className="mx-auto max-w-xl text-lg text-gray-500 sm:mt-4">
            Itinerario:
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {eventsLocal.map((event) => (
            <div key={event.title} className="flex flex-col text-center overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
              {
                event.image ? 
                 
                <img className="h-56 w-full object-cover" src={event.image} alt="" />
                :
                <img className="h-56 w-full object-cover" src="https://htmlcolorcodes.com/assets/images/colors/light-gray-color-solid-background-1920x1080.png" alt="" />
                
              }
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white opacity-80 p-6">
                <div className="flex-1">
                  
                    <p className="text-4xl font-bold font-cursiveEle text-gray-900 ">{event.title}</p>
                    <p className="text-lg font-medium text-gray-800"> {event.place}</p>
                    <p className="text-lg font w-3/4 mx-auto font-semibold text-gray-900 uppercase">{event.dateTime ? dayjs(event.dateTime).format(timeLocale) + "  HRS":null} </p>
                    {/* <p className="text-lg font font-semibold text-gray-900 uppercase">{event.dateTime ? dayjs(event.dateTime).format('LT') : null}</p> */}
                    <p className="mt-3 text-base text-gray-500">Dirección : {event.address}</p>
                    {
                      event.googleLink ? 
                      <a href={event.googleLink} target="_blank" rel="noreferrer">
                      <p className="mt-3 text-base text-blue-500">ver mapa</p>
                      </a>
                      : 
                      null
                    }
                </div>
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  )
}
